/**
 * Assorted libraries that must be on the global namespace.
 * We should try to not use this as much as possible.
 * @type {_|exports|module.exports}
 * @private
 *
 * Understand the expose-loader: https://github.com/webpack/expose-loader
 */

import Bloodhound from 'typeahead.js/dist/bloodhound';
import Strftime from 'strftime';
import _ from 'lodash';
import typeahead from 'typeahead.js/dist/typeahead.jquery';
import * as awc from '@atlassiansox/analytics-web-client';

if (typeof window.awc_resolve === 'undefined' || window.awc_resolve === null) {
  window.awc = new Promise(function(resolve, _reject) {
    window.awc_resolve = resolve;
  });
}
window.awc_resolve(awc);

Object.assign(window, {
  Bloodhound,
  Strftime,
  _,
  typeahead,
});
